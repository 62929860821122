<template>
  <div></div>
</template>

<script>
export default {
  name: 'SilentRenew',
  beforeMount() {
    this.$oidc.signinSilentCallback()
  },
}
</script>
